import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Image from 'reusecore/src/elements/Image';
import Container from 'common/src/components/UI/Container';

import PartnerSectionWrapper from './partner.style';
import Partner from 'common/src/assets/image/saas/rheem-websuite-logo.png';

const PartnerSection = ({
  row,
  col,
  title,
  description,
  button,
  textArea,
  imageArea,
  brand,
}) => {
  return (
    <PartnerSectionWrapper>
      <Container>
        <Box {...row}>
          {brand !== 'rheem' ? (
            <Box {...col} {...imageArea}>
              <Image src={Partner} alt="Domain Image" />
            </Box>
          ) : null}

          {brand !== 'rheem' ? (
            <Box {...col} {...textArea}>
              <Heading {...title} content="Rheem WebSuite Case Study" />
              <Text
                {...description}
                content="Rheem has been running a private label version of goBoost for years. They realized the importance of helping out their channel partners keep up online without breaking the bank."
              />
              <Box>
                <a
                  href="https://hubs.ly/H0kZ6wH0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button {...button} title="Download The Case Study" />
                </a>
              </Box>
            </Box>
          ) : null}
        </Box>
      </Container>
    </PartnerSectionWrapper>
  );
};

PartnerSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  textArea: PropTypes.object,
  imageArea: PropTypes.object,
};

PartnerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['100%', '100%', '55%', '50%', '42%'],
  },
  imageArea: {
    width: ['100%', '100%', '45%', '50%', '58%'],
    mb: ['40px', '40px', '0', '0', '0'],
  },
  title: {
    fontSize: ['26px', '30px', '30px', '48px', '48px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '15px',
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '33px',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
};

export default PartnerSection;
