import React, { Fragment, useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
//import Image from 'reusecore/src/elements/Image';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import Particles from '../Particle';
import BannerWrapper, {
  BannerObject,
  BannerObjectMobile,
} from './bannerSection.style';
import axios from 'axios';
// import BannerObject1 from 'common/src/assets/image/saas/banner/bannerObject1.png';
// import BannerObject2 from 'common/src/assets/image/saas/banner/bannerObject2.png';
// trying to trigger a deploy
const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  discountText,
  discountAmount,
  outlineBtnStyle,
  name,
  company,
  persona,
  focus,
  videoURL,
  tagline,
  smbType,
}) => {
  const [video, setVideo] = useState(
    'https://goboost.hippovideo.io/video/embed/32sPL8HgNSL4QRqcRVcFG1wnnzxnIX8OIt3gI_G36N4?autoplay=false'
  );

  useEffect(() => {
    const fetchData = async prefix => {
      if (!prefix) {
        return;
      }
      const result = await axios
        .get(
          `https://www.googleapis.com/storage/v1/b/go-boost-sales-videos/o?prefix=${prefix}`
        )
        .then(videos => {
          let video = videos.data['items'].filter(v =>
            v.name.includes(prefix)
          )[0];
          return video ? video : null;
        })
        .then(video => axios.get(video.mediaLink))
        .then(response => {
          return response.data;
        })
        .catch(error => {
          return null;
        });

      if (result) {
        setVideo(result);
      }
    };

    if (videoURL) {
      setVideo(videoURL);
      return;
    } else {
      let prefix = '';
      if (persona) {
        prefix = persona;
      } else if (focus) {
        prefix = focus;
      }
      fetchData(prefix);
    }
  }, []);

  const ButtonGroup = () => (
    <Fragment>
      <a
        href="https://meetings.hubspot.com/steve490"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button title="Let's Talk" {...btnStyle} />
      </a>
      <a href="#service_section">
        <Button
          className="outlined"
          title="Learn more"
          variant="outlined"
          {...outlineBtnStyle}
        />
      </a>
    </Fragment>
  );

  return (
    <div>
      <BannerWrapper id="banner_section">
        <Particles />
        <Container>
          <Box className="row" {...row}>
            <Box className="col" {...col}>
              <Heading content={`${name ? `Hey ${name},` : ''}`} {...title} />
              <FeatureBlock
                title={
                  <Heading
                    content={
                      tagline
                        ? tagline
                        : `Let's Make Every ${smbType} Your Best ${smbType}`
                    }
                    {...title}
                  />
                }
                description={
                  <Text
                    content={`We work with corporate marketing departments to develop great marketing playbooks for all your ${smbType} partners and then use our software to make it easy for your ${smbType} partners to promote their business and your products.`}
                    {...description}
                  />
                }
                button={<ButtonGroup />}
              />
            </Box>
          </Box>
        </Container>
        <BannerObject>
          <div className="objectWrapper">
            <div
              style={{
                position: 'relative',
                paddingBottom: '62.5%',
                height: '500px',
                width: '800px',
                top: '0px',
                marginRight: '60px',
              }}
            >
              <iframe
                title="intro video"
                src={video}
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen={true}
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%',
                }}
              ></iframe>
            </div>
            <div className="dashboardWrapper"></div>
          </div>
        </BannerObject>
        <BannerObjectMobile>
          <div className="objectWrapper">
            <div
              style={{
                position: 'relative',
                paddingBottom: '62.5%',
                height: '500px',
                width: '800px',
                top: '0px',
                marginRight: '60px',
              }}
            >
              <iframe
                title="intro video"
                src={video}
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen={true}
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '58%',
                  height: '100%',
                }}
              ></iframe>
            </div>
            <div className="dashboardWrapper"></div>
          </div>
        </BannerObjectMobile>
      </BannerWrapper>
    </div>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, '70%', '50%', '45%'],
  },
  title: {
    fontSize: ['22px', '34px', '30px', '55px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '0',
  },
  btnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'primaryWithBg',
  },
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#3ac0a2',
    ml: '18px',
  },
  discountAmount: {
    fontSize: '14px',
    color: '#3ac0a2',
    mb: 0,
    as: 'span',
    mr: '0.4em',
    fontWeight: 700,
  },
  discountText: {
    fontSize: ['13px', '14px'],
    color: '#0f2137',
    mb: 0,
    as: 'span',
    fontWeight: 500,
  },
};

export default BannerSection;
